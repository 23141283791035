import { AppointmentTypes, StoreTypes, UserTypes } from 'common';
import { useInjection } from 'inversify-react';

import { WizardStep } from 'domain/entities/WizardStep';
import { AsyncHookResult, InjectableHook, useHookInjection } from 'domain/hooks';
import { ButtonSection } from 'infrastructure/components/ButtonsGroup';

import {
  BookingWizardNavigationHook,
  IBookingWizardNavigationHook,
} from './useCaseNavigateBookingWizard';

export enum SessionLengthError {
  Unknown = 'unknown',
}

export interface SessionLengthForm {
  location: string;
  reservationDate: '' | Date | undefined;
  partySize: string;
  startDateTime: string;
  endDateTime: string;
  staffId: null;
  sessionLength: null;
  foodOrderAvailability: StoreTypes.FoodOrderAvailability;
}

export const SessionLengthAdapter = Symbol('SessionLengthAdapter');
export type SessionLengthAdapterExecutor = {
  isSuccess: boolean;
  availableTimeSlots: ButtonSection[] | undefined;
  getPricing: (values: AppointmentTypes.AppointmentPricingRequest) => void;
  areAppointmentsLoading: boolean;
  appointmentError: SessionLengthError | undefined;
  getWholeDayPricing: (values: AppointmentTypes.HourlySimulatorPricingRequestDto) => void;
  wholeDayPrices: AppointmentTypes.HourlySimulatorPricingDto | undefined;
  areWholePricesLoading: boolean;
  getAvailabilities: (values: {
    locationId: string;
    startDateTime: string;
    endDateTime: string;
    partySize: string;
    email?: string;
  }) => void;
  refetchAvailabilities: () => void;
  userData: UserTypes.UserSummary;
  handleGetUserData: (newLocationId: string) => void;
  handleSaveAppointmentDataToStore: (values: SessionLengthForm) => void;
  handleSavePartySizeToStore: (partySize: string) => void;
  handleSaveReservationDateToStore: (reservationDate: Date) => void;
};
export type ISessionLengthAdapter = InjectableHook<
  AsyncHookResult<AppointmentTypes.ServicePricing[], SessionLengthError> &
    SessionLengthAdapterExecutor
>;

const useCaseChooseSessionLength = (currentStep?: WizardStep) => {
  const bookingWizardNavigation = useHookInjection<IBookingWizardNavigationHook>(
    BookingWizardNavigationHook,
  );

  const adapter = useInjection<ISessionLengthAdapter>(SessionLengthAdapter);
  const {
    isSuccess,
    error,
    result,
    inProgress,
    availableTimeSlots,
    getPricing,
    areAppointmentsLoading,
    appointmentError,
    getWholeDayPricing,
    wholeDayPrices,
    areWholePricesLoading,
    getAvailabilities,
    refetchAvailabilities,
    userData,
    handleGetUserData,
    handleSaveAppointmentDataToStore,
    handleSavePartySizeToStore,
    handleSaveReservationDateToStore,
  } = adapter();

  const handleNextStep = (values: SessionLengthForm) => {
    if (!currentStep) {
      return;
    }

    handleSaveAppointmentDataToStore(values);

    if (bookingWizardNavigation && currentStep) {
      if (
        values.partySize === '7-12' &&
        values.foodOrderAvailability !== StoreTypes.FoodOrderAvailability.NotAvailable
      ) {
        bookingWizardNavigation.redirectToStep(WizardStep.preOrderFood);
      } else if (!userData?.creditCard) {
        bookingWizardNavigation.redirectToStep(WizardStep.paymentDetails);
      } else {
        bookingWizardNavigation.redirectToNextStep(currentStep);
      }
    }
  };

  const refetchUserDataWhenLocationIdChanged = (newLocationID: string) => {
    handleGetUserData(newLocationID);
  };

  const preselectPartySize = (partySizeSlug: string | null) => {
    if (partySizeSlug) {
      handleSavePartySizeToStore(partySizeSlug);
    }
  };

  const preselectReservationDate = (reservationDate: string | null) => {
    if (reservationDate) {
      handleSaveReservationDateToStore(new Date(reservationDate));
    }
  };

  return {
    isSuccess,
    error,
    result,
    inProgress,
    availableTimeSlots: availableTimeSlots,
    nextStep: handleNextStep,
    getAppointmentPricing: getPricing,
    getWholeDayPricing,
    getAvailabilities,
    refetchAvailabilities,
    wholeDayPrices,
    areWholePricesLoading,
    areAppointmentsLoading,
    appointmentError,
    refetchUserDataWhenLocationIdChanged,
    prevStep: () => {
      if (currentStep && bookingWizardNavigation)
        bookingWizardNavigation.redirectToPrevStep(currentStep);
    },
    preselectPartySize,
    preselectReservationDate,
  };
};

export default useCaseChooseSessionLength;
